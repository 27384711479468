// Main Client Script goes here

// Logout function
function logout() {
  fetch('/logout', { method: 'POST' })
    .then(() => {
      window.location.href = '/';
    })
    .catch((error) => {
      console.error('Logout error:', error);
    });
}

console.log('_client main loaded...')


document.addEventListener('DOMContentLoaded', function () {
  const logoutButton = document.getElementById('logout-button');
  if (logoutButton) {
    logoutButton.addEventListener('click', logout);   
  }
});



// Toggle Navbar item 'active'
$(function() {
  // Get the current page URL
  var currentUrl = window.location.href;

  // Get all the navbar links
  var navLinks = $('.navbar-nav').find('.nav-link');

  // Loop through each navbar link
  navLinks.each(function() {
    // Get the link URL
    var linkUrl = $(this).attr('href');

    // Check if the link URL matches the current page URL
    if (currentUrl.indexOf(linkUrl) !== -1) {
      // Add the 'active' class to the link
      $(this).addClass('active');
    } else {
      // Remove the 'active' class from other links
      $(this).removeClass('active');
    }
  });
});


// Music ('app') specific javascript
//import './music'
var tracks = []; // Global array holding all 4000 tracks objects fetched from the server
var filteredTracks = []; // Global array to hold the filtered tracks

const batchSize = 100; // Number of cards to load at a time
let startIndex = 0; // Starting index for loading cards
let currentCategory = null; // Currently selected category for filtering
let previousCategory = null;
let currentSearchQuery = ''; // Current search query

const container = document.getElementById('music-container');
const loadingSpinner = document.getElementById('loading-spinner');
const searchInput = document.getElementById('search-input');


// When the document is loaded, fetch the music data from the server
document.addEventListener('DOMContentLoaded', async () => {
  const uri = '/getMusic';
  
  try {
    console.warn('fetching data from the server....')
    const response = await fetch(uri);

    // Check if the response was successful
    if (!response.ok) {
      throw new Error('Request failed with status ' + response.status);
    }

    const currentLocation = window.location.href;
    history.pushState({}, "", currentLocation);

    // Get the response text
    tracks = JSON.parse(await response.text());
    console.log('number of tracks fetched:', tracks.length)

    // Update the total Tracks count
    const totalTracks = document.getElementById('totalTracks');
    totalTracks.innerHTML = tracks.length;

    // Initialize filteredTracks with all tracks initially
    filteredTracks = tracks; 

    // Populate the category filter buttons
    createCategoryButtons();

    // Initial load of cards
    filterTracks();

  } catch(err) {
    console.error('An error occurred during loadContent:', err);
  }    
});


// Function to filter the tracks based on the selected category and search query (PREVIOUS VERSION)
/* const filterTracks = () => {
  // Clear the container before applying the filter
  container.innerHTML = '';

  filteredTracks = tracks.filter(track => {
    const matchesCategory = !currentCategory || track.category === currentCategory;
    const matchesSearchQuery = track.displayTitle.toLowerCase().includes(currentSearchQuery.toLowerCase());
    return matchesCategory && matchesSearchQuery;
  });

  // Reset the startIndex when applying a new filter
  startIndex = 0;

  // Render the first batch of filtered tracks
  renderNextBatch(filteredTracks);
}; */

// Function to filter the tracks based on the selected category and search query
const filterTracks = () => {
  // Clear the container before applying the filter
  container.innerHTML = '';

  if (currentCategory === previousCategory) {
    // Reset the filter (toggle off)
    currentCategory = null;
  }

  filteredTracks = tracks.filter(track => {
    const matchesCategory = !currentCategory || track.category === currentCategory;
    const matchesSearchQuery = track.displayTitle.toLowerCase().includes(currentSearchQuery.toLowerCase());
    return matchesCategory && matchesSearchQuery;
  });

  // Set previousCategory for the next check
  previousCategory = currentCategory;

  // Reset the startIndex when applying a new filter
  startIndex = 0;

  // Render the first batch of filtered tracks
  renderNextBatch(filteredTracks);
};



// Function to render the next batch of tracks
const renderNextBatch = () => {
//setTimeout(console.log('waiting 2000 ms'), 2000)

//loadingSpinner.style.display = 'block';
loadingSpinner.classList.remove('d-none');
  

const endIndex = Math.min(startIndex + batchSize, filteredTracks.length);
for (let i = startIndex; i < endIndex; i++) {
  const card = createCardForTrack(filteredTracks[i]);
  container.insertAdjacentHTML('beforeend', card);
}


// Attach download link event
//initDownloadLinks()

startIndex += batchSize;
console.log('startIndex:',startIndex)
//loadingSpinner.style.display = 'none';
loadingSpinner.classList.add('d-none');

if (startIndex < filteredTracks.length) {
  setTimeout(() => {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 200) {
      renderNextBatch(filteredTracks); // Pass filteredTracks as an argument
    }
  }, 200);
}

};

// Event listener for scrolling
window.addEventListener('scroll', () => {
if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 200) {
  renderNextBatch(filteredTracks); // Pass filteredTracks as an argument
}
});


// Event listener for filter buttons
const filterButtons = document.querySelectorAll('.filter-button');
filterButtons.forEach(button => {
  button.addEventListener('click', (event) => {
    const category = event.target.dataset.category;
    currentCategory = category;
    filterTracks();
  });
});


// Event listener for search input
searchInput.addEventListener('input', (event) => {
  const searchQuery = event.target.value.trim();
  currentSearchQuery = searchQuery;
  filterTracks();
});

function createCardForTrack(obj) {
  let imgHtml = '';
  if (obj.thumbnail) {
    imgHtml = `<img src="${obj.thumbnail}" class="card-img-top" alt="${obj.displayTitle}">`
  }
  const cardHtml = `
    <div class="card m-1" style="width: 22rem;">
      <img src="${obj.thumbnail || 'images/og_image.png'}" class="card-img-top" alt="${obj.displayTitle}">
      <div class="card-body">
        <h5 class="card-title">${obj.displayTitle}</h5>
        <p class="card-text"><code>#${obj.category}</code> - ${obj.DateFirstSeen}</p>
        <a href="${obj.ytUrl}" target="_blank" class="btn btn-primary">Listen</a>
        ${ (obj.ytUrl.includes('https://t.me/c/')) ? '<a role="button" class="btn btn-info" href="/download?id='+obj.videoId+'&name='+encodeURIComponent(obj.displayTitle)+'" target="blank">Download</a>' : ''}
      </div>
    </div>
  `;
  return cardHtml;
}

/*
${ (obj.ytUrl.includes('https://t.me/c/')) ? '<button class="btn btn-info downloadButton" data-id="'+obj.videoId+'" data-name="'+obj.displayTitle+'">Download</button>' : ''}
*/


/* function createCategoryButtons() {
  const categories = [];
  
  // Extract unique categories from the tracks
  tracks.forEach(track => {
    if (!categories.includes(track.category)) {
      categories.push(track.category);
    }
  });
  
  // Create the button group HTML
  const buttonGroup = document.createElement('div');
  buttonGroup.classList.add('button-group');
  
  categories.forEach(category => {
    const button = document.createElement('button');
    button.textContent = category;
    button.addEventListener('click', function() {
      // Filter tracks by category and update the display
      const filteredTracks = tracks.filter(track => track.category === category);
      displayTracks(filteredTracks);
    });
    
    buttonGroup.appendChild(button);
  });
  
  // Get the filter buttons container and append the button group
  const filterButtonsContainer = document.getElementById('filter-buttons');
  filterButtonsContainer.appendChild(buttonGroup);
} */


// Function to create the category filter buttons (PREV VERSION)
/* const createCategoryButtons = () => {
  const filterButtonsContainer = document.getElementById('filter-buttons');

  const categories = [];
  
  // Extract unique categories from the tracks
  tracks.forEach(track => {
    if (!categories.includes(track.category)) {
      categories.push(track.category);
    }
  });

  categories.forEach(category => {
    const button = document.createElement('button');
    button.setAttribute('type', 'button');
    button.classList.add('btn', 'btn-primary');
    button.textContent = category;
    button.addEventListener('click', function() {
      // Remove the active class from all buttons
      const buttons = filterButtonsContainer.querySelectorAll('button');
      buttons.forEach(btn => {
        btn.classList.remove('active');
      });

      // Add the active class to the clicked button
      button.classList.add('active');

      // Update the current category and trigger filtering
      currentCategory = category;
      filterTracks();
    });

    filterButtonsContainer.appendChild(button);
  });
}; */



/* const createCategoryButtons = () => {
  const filterButtonsContainer = document.getElementById('filter-buttons');

  const categories = [];
  
  // Extract unique categories from the tracks
  tracks.forEach(track => {
    if (!categories.includes(track.category)) {
      categories.push(track.category);
    }
  });

  categories.forEach(category => {
    const button = document.createElement('button');
    button.setAttribute('type', 'button');
    button.classList.add('btn', 'btn-primary');
    button.textContent = category;
    button.addEventListener('click', function(event) {
      // Remove the active class from all buttons
      const buttons = filterButtonsContainer.querySelectorAll('button');
      buttons.forEach(btn => {
        btn.classList.remove('active');
      });

      // Add the active class to the clicked button
      event.target.classList.add('active');

      // Update the current category and trigger filtering
      currentCategory = category;
      filterTracks();
    });

    filterButtonsContainer.appendChild(button);
  });
}; */


const createCategoryButtons = () => {
  const filterButtonsContainer = document.getElementById('filter-buttons');

  const categories = [];
  
  // Extract unique categories from the tracks
  tracks.forEach(track => {
    if (!categories.includes(track.category)) {
      categories.push(track.category);
    }
  });

  categories.forEach(category => {
    const button = document.createElement('button');
    button.setAttribute('type', 'button');
    button.classList.add('btn', 'btn-primary');
    button.textContent = category;
    button.addEventListener('click', function(event) {
      // Check if the clicked button is already active
      const isActive = event.target.classList.contains('active');
      
      // Remove the active class from all buttons
      const buttons = filterButtonsContainer.querySelectorAll('button');
      buttons.forEach(btn => {
        btn.classList.remove('active');
      });

      // If the clicked button was not already active, add the active class
      if (!isActive) {
        event.target.classList.add('active');
        currentCategory = category;
      } else {
        // If the clicked button was already active, reset the current category
        currentCategory = null;
      }

      // Trigger filtering
      filterTracks();

      // Remove focus from the clicked button
      event.target.blur();
    });

    filterButtonsContainer.appendChild(button);
  });
};





/**
 * TG Track DL
 * */
// document.addEventListener('DOMContentLoaded', function () {
//   console.log('getting ready the download ajax...')
//   initDownloadLinks()
// });


// function initDownloadLinks() {
//   // remove existing click event handlers first
//   removeDownloadLinks();

//   $(document).on('click', '.downloadButton', function() {
//     var fileId = $(this).data('id'); // Get the ID from the button's data-id attribute
//     var fileName = $(this).data('name');
  
//     // Send GET request to /download with the ID as a query parameter
//     $.ajax({
//       url: '/download',
//       type: 'GET',
//       data: { id: fileId, name: fileName }, // Pass the ID as a query parameter
//       responseType: 'arraybuffer', // Specify the response type as arraybuffer
  
//       /* success: function(response) {
//         // Create a Blob object from the response data
//         var blob = new Blob([response], { type: 'audio/mpeg' });
  
//         // Create a temporary URL for the Blob object
//         var blobUrl = URL.createObjectURL(blob);
  
//         // Create a temporary link to download the file
//         var link = document.createElement('a');
//         link.href = blobUrl;
//         link.download = fileName;
//         link.click();
  
//         // Clean up the temporary URL
//         URL.revokeObjectURL(blobUrl);
//       }, */

//       success: function(response) {
        
//       },
//       error: function (error) {
//         console.log('Error:', error); 
//       }
//     });
//   });

//   // $('.downloadButton').click(function () {
//   //   var fileId = $(this).data('id'); // Get the ID from the button's data-id attribute
//   //   var fileName = $(this).data('name');

//   //   // Send GET request to /download with the ID as a query parameter
//   //   $.ajax({
//   //     url: '/download',
//   //     type: 'GET',
//   //     data: { id: fileId, name: fileName }, // Pass the ID as a query parameter
//   //     success: function(data) {
//   //       var link = document.createElement('a');
//   //       link.href = data.fileUrl;
//   //       link.download = data.fileName;
//   //       link.click();
//   //     },
//   //     /* success: function (data) {
//   //       // Create a Blob object from the response data
//   //       var blob = new Blob([data], { type: 'audio/mpeg' });

//   //       // Create a temporary URL for the Blob object
//   //       var blobUrl = URL.createObjectURL(blob);

//   //       // Create a temporary link to download the file
//   //       var link = document.createElement('a');
//   //       link.href = blobUrl;
//   //       link.download = fileName;
//   //       link.click();

//   //       // Clean up the temporary URL
//   //       URL.revokeObjectURL(blobUrl);
//   //     }, */
//   //     error: function (error) {
//   //       console.log('Error:', error);
//   //     }
//   //   });
//   // });
// }

// function removeDownloadLinks() {
//   $('.downloadButton').off('click');
// }


module.exports = {
  logout
}
